<template>
  <section class="details">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <h1 class="title">{{ options.title }}</h1>
      <div>
        <section v-for="(item, index) in options.content" :key="index">
          <h5 v-if="item.title" class="details-title">{{ item.title }}</h5>
          <ArticleContent :data="item.content" />
          <!-- <section v-for="(val, ind) in item.content" :key="ind" class="details-main">
            <h6 v-if="val.tit">{{ val.tit }}</h6>
            <div v-else-if="val.collapse">
              <el-collapse :v-model="'activeName' + index" accordion :class="'activeName' + index" @change="handleCollapseChange">
                <el-collapse-item  v-for="(org, i) in val.collapse" :key="i" :name="i + 1" @click.native="handleCollapseClick('activeName' + index)">
                  <template slot="title" right-icon="el-icon-plus">
                    {{ org.tit }} <i :class="judgeActive('activeName' + index, i + 1) ? 'el-icon-minus el-collapse-item__arrow is-active' : 'el-collapse-item__arrow el-icon-plus'"></i>
                  </template>
                  <section v-for="(row, j) in org.content" :key="j" class="details-main-content">
                    <h6 v-if="row.tit">{{ row.tit }}</h6>
                    <p v-else-if="row.con">{{ row.con }}</p>
                    <div v-else-if="typeof(row.url) == 'object' && row.url.length > 1">
                      <el-row :gutter="30">
                        <el-col v-for="(pic, i) in row.url" :key="i" :span="row.url.length == 2 ? 12 : row.url.length == 3 ? 8 : 6"><img :src="pic" /></el-col>
                      </el-row>
                    </div>
                    <img v-else :src="row.url">
                  </section>
                </el-collapse-item>
              </el-collapse>
            </div>
            <p v-else-if="val.con">{{ val.con }}</p>
            <div v-else-if="typeof(val.url) == 'object' && val.url.length > 1">
              <el-row :gutter="30">
                <el-col v-for="(pic, i) in val.url" :key="i" :span="val.url.length == 2 ? 12 : val.url.length == 3 ? 8 : 6"><img :src="pic" /></el-col>
              </el-row>
            </div>
            <img v-else :src="val.url">
          </section> -->
        </section>
      </div>
    </section>
  </section>
</template>

<script>
import imageBanner from '../components/ImageBanner.vue'
import ArticleContent from '../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data() {
    return {
      activeName0: '0',
      activeName1: '0',
      activeName2: '0',
      activeName3: '0',
      activeName4: '0',
      activeName5: '0',
      activeName6: '0',
      activeName7: '0',
      activeName8: '0',
      activeName9: '0',
      activeName10: '0',
      activeName11: '0',
      activeName12: '0',
      activeName13: '0',
      activeName14: '0',
      options: {title: '', content: []},
      imgBannerObj: {
        url: require('../assets/images/aboutus/06-可持续发展.png'),
        slogan: require('../assets/images/common/slogan.png'),
      },
      customElements: '',
      activeNames: null
    };
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let option
      if ( id ) {
        switch ( id ) {
          case '1':
            option = JSON.parse(JSON.stringify(this.$store.getters.getNmps))
            this.setOptions(option)
            break
          case '2':
            option = this.$store.state.infrared
            this.setOptions(option)
            break
          case '3':
            option = this.$store.state.microenvironment
            this.setOptions(option)
            break
          case '4':
            option = this.$store.state.ai
            this.setOptions(option)
            break
          case '5':
            option = this.$store.state.substation
            this.setOptions(option)
            break
          case '6':
            option = this.$store.state.charge
            this.setOptions(option)
            break
          default:
            option = this.$store.state.nmps
            this.setOptions(option)
        }
      }
    },
    setOptions (obj) {
      this.$set(this.options, 'title', obj.title)
      this.$set(this.options, 'content', obj.content)
    },
  },
  mounted () {

  }
}
</script>

<style scoped>
  .details {background-color: #ecf0f5; padding-bottom: 1rem;}
  .container {padding: 2rem 5%; text-align: left;}
  .container h1.title {padding: 2rem 0; text-align: center; background-color: #fff; font-size: 1.6rem; font-weight: 600;}
  .container .details-title {font-size: 1.4rem; font-weight: 600; background-color: #fff; line-height: 2;}
  .container .details-main {padding: 0.1rem 2rem; background-color: #fff;}
  .container /deep/ .el-collapse-item__header {font-size: 1.1rem; font-weight: 600;}
  .el-collapse {padding: 1rem 4rem; background-color: #fff; border: none;}
  .container h6 {font-weight: 600; font-size: 1rem;}
  .container p {text-indent: 2em; line-height: 2; font-size: 1rem;}
  .container /deep/ .el-collapse-item__arrow.is-active {transform: rotate(180deg)}
  .container /deep/ .el-collapse-item__arrow.el-icon-arrow-right {display: none;}
</style>